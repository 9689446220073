.text-image {
	margin: rem-calc(50 0);

	.text-image-content {
		font-size: rem-calc(30)*0.7;
		font-family: $font-alt;
		font-weight: 700;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		color: $yellow;
		margin-top: rem-calc(20);

		@include breakpoint(small down) {
			text-align: center;
		}

		@include breakpoint(medium) {
			margin-top: 0;
		}
		
		@include breakpoint(large) {
			font-size: rem-calc(30);
		}

		h2 {
			color: $yellow;
			font-size: rem-calc(80);
			margin: 0;
			padding: rem-calc(30 0);
			position: relative;
			margin-left: rem-calc(-70);
		}

		p {
			padding: rem-calc(0 50);
			font-family: $font-base;
			font-weight: 300;
			font-size: rem-calc(18);
			margin: 0 0 1.875rem 0;
		}
	}

	.medium-pull-6 {
		.text-image-content:before {
			left: auto;
			right: rem-calc(-30);
		}
	}

	.medium-push-6 {
		.text-image-content {
			h2 {
				margin-left: rem-calc(0);
			}
		}
	}

	.text-image-image {
		display: flex;
		align-items: center;
		align-content: center;

		img {
			width: 100%;
			height: auto;
		}
	}
}

.text-image-wrapper {
    text-align: right;
    position: relative;

    .text-image-decoration {
		background-image: url(../img/dots.png);
        position: absolute;
		top: 0;
    }
}