.home-intro {
	position: relative;
	// overflow: hidden;
	padding: rem-calc(80) 0;
    margin-top: rem-calc(-30);
    
    .home-intro-left {
        padding: 0 rem-calc(10);
        
		@include breakpoint(medium) {
			padding: 0 rem-calc(15);
		}

        .home-intro-image {
            position: relative;
    
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: rem-calc(2000);
                height: 100%;
                top: rem-calc(-80);
                right: 80%;
                background-image: url(../img/dots-intro.jpg);
                z-index: 1;
            }
    
            img {
                position: relative;
                z-index: 2;
            }
            
            &:after {
                @include breakpoint(medium) {
                    content: "";
                    display: block;
                    position: absolute;
                    height: rem-calc(80);
                    top: rem-calc(-80);
                    right: 0;
                    z-index: 1;
                    border-left: rem-calc(1) solid $gray;
                }
            }
        }
	}

	.home-intro-right {
		position: relative;
		z-index: 10;
        padding: 0 rem-calc(10);
        
		@include breakpoint(medium) {
			padding: 0 rem-calc(15) 0 0;
		}

        h2 {
            font-size: rem-calc(90)*0.5;
            line-height: 1.2;
            color: $yellow;
            font-weight: 900;
            font-family: $font-alt;
            margin-top: 10px;
            padding: rem-calc(15)*0.75 0;
            
            @include breakpoint(medium) {
                margin-top: 0;
                border-bottom: rem-calc(1) solid $gray;
                font-size: rem-calc(90)*0.75;
                line-height: 1.4;
                padding: rem-calc(15)*0.75 0;
                margin-bottom: rem-calc(30)*0.75;
                margin-left: rem-calc(-50);
            }
            
            @include breakpoint(xxlarge) {
                font-size: rem-calc(90);
                padding: rem-calc(15) 0;
                margin-bottom: rem-calc(30);
            }
        }
    
        .home-intro-content {
            max-width: rem-calc(550);
            margin: 0 auto;
            padding: 0;
            
            @include breakpoint(medium) {
                padding: rem-calc(50)*0.75;
            }
            
            @include breakpoint(xxlarge) {
                padding: rem-calc(50);
            }
    
            .button {
                margin-top: rem-calc(30);
            }
    
            *:last-child {
                margin-bottom: 0;
            }
        }
	}
}