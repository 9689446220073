.info-grid {
    .row {
        .column {
            border-top: rem-calc(1) solid #796900;
            
            @include breakpoint(medium) {
                border-top: none;
            }
            
            &:last-of-type {
                border-bottom: rem-calc(1) solid #796900;

                @include breakpoint(medium) {
                    border-bottom: none;
                }
            }

            &:nth-of-type(n+4) {
                @include breakpoint(medium) {
                    border-top: rem-calc(1) solid #796900;
                }

                @include breakpoint(large) {
                    border-top: none;
                }
            }
            
            &:nth-of-type(3n + 2) {
                @include breakpoint(medium) {
                    border-left: rem-calc(1) solid #796900;
                    border-right: rem-calc(1) solid #796900;
                }
            }
            
            &:nth-of-type(3n + 1) {
                @include breakpoint(large) {
                    border-left: rem-calc(1) solid #796900;
                }
            }

            &:nth-of-type(3n + 3) {
                @include breakpoint(large) {
                    border-right: rem-calc(1) solid #796900;
                }
            }
        }
    }
    
    .info-grid-box {
        height: rem-calc(280)*0.5;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        
        @include breakpoint(medium) {
            height: rem-calc(280) * 0.75;
        }
        
        @include breakpoint(xxlarge) {
            height: rem-calc(280);
        }
        
        h3 {
            color: $black;
            font-size: rem-calc(30);
            font-weight: 900;
            margin: 0;
            padding: rem-calc(15);
            position: relative;
            z-index: 10;
            transition: all 0.3s ease;
        
            @include breakpoint(xlarge down) {
                font-size: rem-calc(30) * 0.75;
            }
        }
        .box-bg {
            @include breakpoint(large) {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url(../img/group-48.jpg);
                transform: translate3d(0,100%,0);
                transition: all 0.3s ease;
                z-index: 1;
            }
  
            &:before {
                @include breakpoint(large) {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,0.5);
                }
            }
      }
        &:hover {
            
            .box-bg {
                @include breakpoint(large) {
                    transform: translate3d(0,0,0);
                }
            }
            
            h3 {
                @include breakpoint(large) {
                    color: $white;
                }
            }
        }
    }
}