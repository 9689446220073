.person-list {
	.person-list-item {
		margin-bottom: rem-calc(60);
		@include clearfix;
		
		.person-list-thumbnail {
			position: relative;
			width: 50%;
			float: left;
			padding-bottom: 40%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}

		.person-list-details {
			position: relative;
			width: 50%;
			float: left;
			padding: rem-calc(0 0 100 30);
			position: relative;

			h3 {
				font-size: rem-calc(20);
				font-weight: 700;
				margin-bottom: rem-calc(15);
				display: flex;
				justify-content: center;
                align-items: center;
                
                @include breakpoint(medium) {
                    font-size: rem-calc(28);
                }

                @include breakpoint(large) {
                    font-size: rem-calc(32);
                }
			}
		}

		.person-list-more {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			text-align: right;

			&:before {
				content: '';
				display: block;
				width: 100%;
				border-bottom: rem-calc(1) solid $black;
				position: absolute;
				top: 50%;
				z-index: -1;
			}

			.button {
				margin: 0;
			}
		}

		.person-list-meta {
			padding: rem-calc(5 0 5 15);
			margin-bottom: rem-calc(15);
			border-left: rem-calc(1) solid $black;

			span {
				display: block;
				margin-bottom: rem-calc(5);
			}

			a {
				color: $black;


				&:hover {
					color: $yellow;
				}
			}
		}

		&:nth-child(even) {
			.person-list-thumbnail {
				left: 50%;
			}

			.person-list-details {
				left: -50%;
				padding: rem-calc(0 30 100 0);
			}

			.person-list-more {
				text-align: left;
			}
		}
    }
    
    &.person-list--main {
        .person-list-category {
            @include clearfix;

            .header-yellow {
                font-size: rem-calc(28);
                
                @include breakpoint(large) {
                    font-size: rem-calc(34);
                }
            }
    
            &:first-of-type {
                .person-list-item {
                    &:nth-of-type(odd) {
                        .person-list-details {
                            h3 {
                                justify-content: flex-start;
                            }
                        }
                    }
    
                    &:nth-of-type(even) {
                        .person-list-details {
                            h3 {
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    
        .person-list-category:nth-child(n+2) {
            
            margin: rem-calc(0 -15);
    
            .person-list-padding {
                padding: rem-calc(0 15);
            }
    
            .person-list-item {
                width: 50%;
                float: left;
                
                @include breakpoint(medium) {
                    width: 33.333333%;
                }
    
                .person-list-thumbnail {
                    width: 100%;
                    padding-bottom: 80%;
                    left: auto;
                    height: auto !important;
                }
                .person-list-details {
                    width: 100%;
                    left: auto;
                    padding: rem-calc(15 0 80 0);
    
                    h3 {
                        font-size: rem-calc(18);
                        text-align: center;
                        margin-bottom: rem-calc(10);
                        
                        @include breakpoint(large) {
                            font-size: rem-calc(22);
                        }
                    }
    
                    .person-list-meta {
                        border: 0;
                        text-align: center;
                        margin: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
    
                    .person-list-more {
                        text-align: center;
    
                        &:before {
                            width: auto;
                            height: rem-calc(30);
                            left: 50%;
                            top: rem-calc(-30);
                            border-left: rem-calc(1) solid $black;
                        }
                    }
                }
            }
        }
    }
}