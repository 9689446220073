/*******************************************************************************
  ADMIN STYLES
********************************************************************************/
html, body {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.DesignMode {
  max-width: 100%;

  #header {
    position: relative;
    pointer-events: all;

    .header-fixed {
      position: relative !important;
    }
  }
  
  .top-bar {
    display: block;
  }

  #footer {
    max-width: 1920px;
  }
}
