.cookie-info {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #f2d200;
    width: 100%;
    z-index: 20;
    display: none;
}

.cookie-info__content {
    padding: 10px 10px 15px 10px;
}

@media print, screen and (min-width: 64em) {
    .cookie-info__content {
        padding: 20px 20px 25px 20px;
        display: flex;
    }
}

.cookie-info__text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 5px;
}

@media print, screen and (min-width: 64em) {
    .cookie-info__text {
        margin-bottom: 0;
    }
}

.cookie-info__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media print, screen and (min-width: 40em) {
    .cookie-info__buttons {
        flex-direction: row;
        justify-content: center;
    }
}

@media print, screen and (min-width: 64em) {
    .cookie-info__buttons {
        flex-direction: column;
        margin-left: 30px;
    }
}

.cookie-info__buttons a {
    font-size: 16px;
    color: #0a0a0a;
    border: 1px solid #0a0a0a;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 220px;
    max-width: 220px;
    min-height: 32px;
    max-height: 32px;
    margin-top: 10px;
    text-align: center;
    text-transform: uppercase;
}

@media print, screen and (min-width: 40em) {
    .cookie-info__buttons a:first-of-type {
        margin-right: 10px;
    }
}

@media print, screen and (min-width: 64em) {
    .cookie-info__buttons a:first-of-type {
        margin-right: 0;
        margin-top: 0;
    }
}

@media print, screen and (min-width: 40em) {
    .cookie-info__buttons a:last-of-type {
        margin-left: 10px;
    }
}

@media print, screen and (min-width: 64em) {
    .cookie-info__buttons a:last-of-type {
        margin-left: 0;
    }
}