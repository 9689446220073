#footer {
	.partner-slider {
		padding: rem-calc(30);

		@include breakpoint(medium) {
			padding: rem-calc(50);
		}
		
		.partner-logo {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.footer-main {
		background: #f4f4f4;
		padding: rem-calc(30 0);
		
		@include breakpoint(medium) {
			padding: rem-calc(50 0);
		}
		

		.column {
			font-size: rem-calc(15);
			text-align: center;

			@include breakpoint(medium) {
				text-align: left;
			}

			&:not(:first-of-type) {
				padding-top: 15px;
				
				@include breakpoint(medium) {
					padding-top: 0;
				}
			}

			img {
				padding: rem-calc(0 15);
				
				@include breakpoint(medium) {
					padding: rem-calc(0);
				}
			}
			
			a {
				color: $black;
			}
		}
	}

	.footer-menu {
		text-align: center;
		@include breakpoint(medium) {
			text-align: left;
		}

		ul {
			list-style: none;
			font-size: rem-calc(12);
			margin: 0 auto;
			text-align: center;

			@include breakpoint(medium) {
				text-align: left;
			}

			li {
				@include breakpoint(medium) {
					padding-right: rem-calc(12);
					display: inline-block;
				}
			}
		}
		
	}

	.footer-copyrights {
		background: $yellow;
		padding: rem-calc(15 0);
		
		.column {
			color: $black;
			font-family: $font-alt;
			font-size: rem-calc(14);
			font-weight: 700;
			text-align: center;
			
			&:first-of-type {
				margin-bottom: 5px;
				
				@include breakpoint(medium) {
					margin-bottom: 0;
					text-align: left
				}
			}

			&:last-of-type {
				@include breakpoint(medium) {
					text-align: right;
				}
			}

			a {
				color: $black;
			}
		}
	}
}