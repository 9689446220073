$main-rcs: #b7be18;
$white-rcs: #ffffff;
$gray-rcs: #e3e3e3;
$black-rcs: #000000;

.green-color-scheme {
    // Global
    .button {
        &.primary {
            &:hover,
            &:focus {
                color: $white-rcs;
                background-color: $main-rcs;
                border-color: $main-rcs;
                transition: all 0.25s ease-out;
            }
        }
    }

    .header-yellow {
        color: $main-rcs;
    }

    .breadcrumbs {
        a {
            &.breadcrumbs-home {
                &:hover {
                    color: $main-rcs;
                }
            }
        }
    }

    *[class*=parent] {
        ul {
            li {
                font-size: rem-calc(16);

                @include breakpoint(xlarge down) {
                    font-size: rem-calc(15);
                }

                a {
                    color: $main-rcs;
                }
            }
        }
    }

    //
    .page-hero {
        background-image: url(../img/ks/hero-header.jpg);
    }

    // Cookie info
    .cookie-info {
        background-color: $main-rcs;

        &__text {
            color: $white-rcs;
        }

        &__buttons {
            a {
                color: $white-rcs;
                border-color: $white-rcs;
            }
        }
    }

    // Header
    #header {
        #menuElem {
            margin-left: rem-calc(107);

            @include breakpoint(xxlarge) {
                margin-left: rem-calc(159);
            }

            li {
                display: inline-flex;
                justify-content: center;
                align-items: center;

                a {
                    color: $white-rcs;
                    font-size: rem-calc(16);
                    padding: rem-calc(15);
                    text-align: center;
            
                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(18);
                    }
                }
            }
        }

        .header-search {
            input {
                &[type="text"] {
                    background: $main-rcs;
                    color: $white-rcs;
                }
            }
            
            label {
                color: rgba(255, 255, 255, 0.5);
            }
        }

        .header-search-trigger {
            .header-search-icon {
                background: url(../img/lupa-biala.png) no-repeat center center;
                background-size: contain;
            }
        }
    }

    #megamenu {
        .megamenu-overlay {
            background: $main-rcs;

            .megamenu-line-left,
            .megamenu-line-right {
                border-color: $gray-rcs;
            }

            .megamenu-logo {
                background-color: $main-rcs;
                border-right: rem-calc(1) solid $gray-rcs;
                width: rem-calc(142);
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
                
                @include breakpoint(xlarge down) {
                    width: rem-calc(142) * 0.75;
                }

                a {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(calc(-50% + 3px), -50%);
                    height: auto;
                    text-align: center;
                
                    @include breakpoint(xlarge down) {
                        transform: translate(calc(-50% + (3px * 0.75)), -50%);
                    }

                    img {
                        max-width: 100%;
                        height: auto;
                        margin: auto;
                    }
                }
            }

            .megamenu-trigger {
                .megamenu-icon {
                    span {
                        background: $white-rcs;
                    }
                }
            }

            .megamenu-menu {
                .megamenu-nav {
                    background: $main-rcs;

                    & > ul {
                        & > li {
                            & > a {
                                color: $white-rcs;
                            }

                            & > ul {
                                & > li {
                                    & > a {
                                        color: $white-rcs;
    
                                        &:hover {
                                            color: $black-rcs;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.megamenu-expanded {
        #megamenu {
            .megamenu-overlay {
                .megamenu-logo {
                    width: rem-calc(380);
                    height: rem-calc(100);
                    background-color: transparent;

                    @include breakpoint(xlarge down) {
                        width: rem-calc(295);
                        height: rem-calc(100);
                    }

                    @include breakpoint(medium down) {
                        width: rem-calc(108);
                        height: 100%;
                    }
                }
            }
        }
    }


    // Footer
    #footer {
        .footer-copyrights {
            background: $main-rcs;

            .column {
                color: $white-rcs;

                a {
                    color: $white-rcs;
                }
            }
        }
    }
    .home-form-left {
		@include breakpoint(large) {
            padding-left: rem-calc(225);
            background-size: rem-calc(220);
            margin-left: rem-calc(-80);
            
            background: $white-rcs url(../img/ks/ksiazka.png) no-repeat 0 15%;
            background-size: rem-calc(220);
		}
    }
    


    // Home page
    .home-hero {
        .home-hero-frame {
            .home-hero-scroll {
                color: $white-rcs;
            }
        }

        .home-hero-wrapper {
            .home-hero-image {
                &:before {
                    background: rgba(10, 10, 10, 0.15);
                } 
            }
        }
    }

    .home-intro {
        .home-intro-left {
            .home-intro-image {
                &:before {
                    background: $white-rcs url(../img/ks/dots_zielone.png) repeat top right;
                }
            }
        }

        .home-intro-right {
            h2 {
                color: $main-rcs;

                @include breakpoint(xxlarge) {
                    font-size: rem-calc(72);
                }
            }

            .home-intro-content {
                background: $white-rcs url(../img/ks/jednorozec.png) no-repeat bottom right;
                background-size: rem-calc(175);
                padding-bottom: rem-calc(130);
            }
        }
    }

    .home-numbers {
        padding-bottom: rem-calc(120);
        position: relative;
        
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            margin-left: -1px;
            border-left: rem-calc(1) solid $black-rcs;
            height: rem-calc(85);
            bottom: 0;
        }

        .number-row {
            .number-column {
                .number-box {
                    h3 {
                        color: $main-rcs;
                    }
                }
            }
        }
    }

    .home-page-info {
        background: $main-rcs url(../img/ks/green_banner.jpg) no-repeat top center;
        background-size: contain;
        padding: rem-calc(110 0 110 0);

        h2 {
            margin: 0;
            font-style: italic;
            font-size: rem-calc(44);

            span {
                font-style: normal;
                font-size: rem-calc(32);
            }
        }

        &:before,
        &:after {
            color: $gray-rcs;
        }
    }

    .home-form {
        .home-form-right {
            h2 {
                color: $main-rcs;
                &:before {
                    background: $white-rcs url(../img/ks/dots_szare.png) repeat top left;
                }
            }
        }
    }

    .info-grid .row .column {
        border-color: $black-rcs;
    }

    // Content page
    .sidebar-menu {
        h3 {
            color: $main-rcs;
        }
    }

    .sidebar-filter {
        h3 {
            color: $main-rcs;
        }
    }

    .text-image {
        .text-image-content {
            color: $main-rcs;
        }
    }

    .text-image-wrapper {
        .text-image-decoration {
            background: $white-rcs url(../img/ks/dots_zielone.png) repeat top right;
        }
    }
        


    // Contact
    .main-kontakt {
        .column-contact {
            h3 {
                color: $main-rcs;
            }
            
            a {
                &:hover {
                    color: $main-rcs;
                }
            }
        }
    }

    .contact-map {
        h2 {
            color: $main-rcs;
        }
    }


    // Person
    .person-description {
        &__personal-data {
            div {
                a {
                    &:hover {
                        color: $main-rcs;
                    }
                }
            }   
        }
    }
}