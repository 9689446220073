.home-form {
	padding: rem-calc(135*0.75 0 75*0.75 0);
	overflow: hidden;

	@include breakpoint(medium) {
		padding: rem-calc(135*0.75 0 50*0.75 0);
	}
	
	@include breakpoint(xxlarge) {
		padding: rem-calc(135 0 50);
	}

	.home-form-right {
		padding: 0 0.9375rem;
		position: relative;

		@include breakpoint(medium) {
			padding-bottom: rem-calc(150);
			padding-left: 0;
			padding-right: 0.9375rem;
		}

		@include breakpoint(xxlarge) {
			padding-bottom: rem-calc(150) * 0.75;
			padding-right: 0;
		}

		h2 {
			display: flex;
			position: relative;
			font-weight: 900;
			color: $yellow;
			line-height: 1;
			align-items: center;
			margin: 0;
			height: 100%;
			padding: 2.34375rem 1.625rem;
			justify-content: center;
			font-size: 3.21875rem;
			
			
			@include breakpoint(medium) {
				font-size: rem-calc(90)*0.75;
				justify-content: flex-start;
				padding: rem-calc(50*0.75 120*0.75);
				padding: 2.34375rem 3.625rem;
			}
			
			@include breakpoint(xxlarge) {
				padding: rem-calc(50 120);
				font-size: rem-calc(90);
			}
	
			span {
				position: relative;
				z-index: 2;
			}
	
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-image: url(../img/dots-form.jpg);
				
				@include breakpoint(medium) {
					left: rem-calc(40);
					width: rem-calc(1920);
				}
			}
		}
	}

	.home-form-left {
		position: relative;
		text-align: center;
		padding: 0 0.9375rem;
		
		@include breakpoint(medium) {
			padding-left: 0.9375rem;
			text-align: right;
			padding-right: 0;
		}

		@include breakpoint(large) {
			padding-left: 0;
		}
	
		&:before {

			@include breakpoint(large) {
				content: '';
				display: block;
				position: absolute;
				right: 0;
				bottom: 0;
				height: rem-calc(900);
				border-left: rem-calc(1) solid $gray;
			}
			
		}
	
		> div {
			display: inline-block;
			width: 100%;
			
			@include breakpoint(medium) {
				width: rem-calc(455) * 0.75;
			}
			
			@include breakpoint(xxlarge) {
				width: rem-calc(455);
			}
		}
	
		.FormButton {
			margin-top: rem-calc(30);
			margin-bottom: 0;
			position: relative;
			z-index: 10;
			
			@include breakpoint(medium) {
				margin-top: rem-calc(90) * 0.75;
			}
			
			@include breakpoint(xxlarge) {
				margin-top: rem-calc(90);
			}
		}
	}
}