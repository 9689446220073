.person-description {
    &__header {
        display: flex;
        flex-direction: column;
        
        @include breakpoint(large) {
            flex-direction: row;
        }
    }

    &__image {
        min-width: 100%;
        max-width: 100%;
        padding-top: 72%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin-bottom: 20px;
        
        @include breakpoint(large) {
            margin-right: 25px;
            margin-bottom: 0;
            min-width: 50%;
            max-width: 50%;
            padding-top: 36%;
        }
    }

    &__personal-data {
        h2 {
            font-size: 32px;
            line-height: 32px;
            font-weight: 700;
        }
        
        div {
            border-left: 1px solid black;
            padding-left: 20px;
            

            span,
            a {
                font-size: 20px;
                line-height: 28px;
                color: black;
            }

            span {
                display: block;
            }

            a {
                transition: color 0.3s ease;
                margin-top: 20px;
                display: inline-block;

                &:hover {
                    color: $yellow;
                }
            }
        }
    }

    &__text {
        margin-top: 45px;

        * {
            font-size: 18px;
            line-height: 26px;
            font-weight: 300;
        }
    }
}