.home-page-groups,
.footer-groups {
	padding-bottom: rem-calc(90);
	
	@include breakpoint(medium) {
		padding-bottom: rem-calc(115);
	}

	.groups-header {
		position: relative;
		padding: rem-calc(215*0.75 0 130*0.75);
		text-align: center;
		
		@include breakpoint(xxlarge) {
			padding: rem-calc(215 0 130);
		}

		&:before, &:after {
			content: '';
			display: block;
			position: absolute;
			left: 50%;
			border-left: rem-calc(1) solid $gray;
		}

		&:before {
			height: rem-calc(200);
			top: 0;

			@include breakpoint(xlarge down) {
				height: rem-calc(200) * 0.75;
			}
		}

		&:after {
			height: rem-calc(115);
			bottom: 0;

			@include breakpoint(xlarge down) {
				height: rem-calc(115) * 0.75;
			}
		}

		h2 {
			font-weight: 900;
			margin-bottom: rem-calc(15);
			font-size: rem-calc(48);

			@include breakpoint(xlarge down) {
				font-size: rem-calc(48) * 0.75;
			}
		}

		p {
			max-width: rem-calc(720)*0.75;
			margin: 0 auto;
			
			@include breakpoint(xxlarge) {
				max-width: rem-calc(720);
			}
		}
	}
}

.groups {
	.group-box {
		position: relative;
		margin-top: rem-calc(20);
		
		@include breakpoint(medium) {
			margin-top: 0;
		}

		> a {
			display: block;

			&:hover {
				.group-image {
					&:after {
						@include breakpoint(large) {
							background-color: rgba(0, 0, 0, 0.7);
						}
					}
				}
				
				h3 {
					@include breakpoint(large) {
						color: white;
					}
				}
			}
		}


		.group-image {
			width: 100%;
			padding-bottom: 44%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;

			&:after {
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(0, 0, 0, 0.35);
				
				@include breakpoint(large) {
					transition: background-color .25s ease;
				}
			}
			
			
		}
		
		h3 {
			font-size: rem-calc(80)*0.5;
			font-weight: 900;
			color: $yellow;
			line-height: 1;
			text-decoration: none;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0;
			
			@include breakpoint(large) {
				transition: color .25s ease;
				font-size: rem-calc(80)*0.75;
			}
			
			@include breakpoint(xxlarge) {
				font-size: rem-calc(80);
				padding: rem-calc(45 50);
			}
		}

		&.group-box-03 {
			@include breakpoint(medium) {
				margin-bottom: rem-calc(90)*0.75;
			}
			
			@include breakpoint(xxlarge) {
				margin-bottom: rem-calc(90);
			}

			.group-image {
				background-image: url(../img/group-03.jpg);
			}
		}

		&.group-box-48 {
			@include breakpoint(medium) {
				margin-top: rem-calc(90) * 0.75;
			}
			
			@include breakpoint(xxlarge) {
				margin-top: rem-calc(90);
			}

			.group-image {
				background-image: url(../img/group-48.jpg);
			}
		}
	}
}
.footer-groups {
	padding: rem-calc(0);
	margin-top: rem-calc(50);
	position: relative;

	> .row {
		position: relative;
		z-index: 10;
	}

	.groups-header {
		padding: rem-calc(50 0);

		&:before,
		&:after {
			border-color: $black !important;
			height: rem-calc(50);
		}

		h2 {
			margin-top: rem-calc(30);
		}

		p {
			margin-bottom: rem-calc(30);
		}
	}

	.groups-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		padding-bottom: rem-calc(190);

		&:before {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: $yellow url(../img/dots-info.jpg) repeat-x top center;
		}
	}
}
/*******************************************************************************
	RWD
********************************************************************************/
@include breakpoint(small down) {
	.groups{
		.group-box{
			h3{
				font-size: 2.75rem;
			}
		} 
	}
}