.breadcrumbs {
    margin: rem-calc(30) 0;
    font-size: rem-calc(14);
    padding: rem-calc(7 0);
    
    @include breakpoint(medium) {
        margin: 0 0 rem-calc(30) 0;
    }
    
    a {
        color: $black;
        text-decoration: none;
        
        &.breadcrumbs-home {
            display: inline-block;
            background: url(../img/breadcrumb-home.png) no-repeat left center;
            padding-left: rem-calc(20);
            
            
            &:hover {
                text-decoration: none;
                color: $yellow;
            }
        }
    }
}