.yellow-color-scheme {
    #header {
        #menuElem {
            @include breakpoint(large up) {
                align-items: center;
            }
            > li {
                @include breakpoint(large up) {
                    text-align: center;
                    padding: rem-calc(15);
                }
                a {
                    @include breakpoint(large up) {
                        font-size: rem-calc(14);
                        padding: 0;
                    }

                    @include breakpoint(xxlarge up) {
                        font-size: rem-calc(20);
                    }
                }

                &.is-dropdown-submenu-parent {
                    > a {
                        &:after {
                            border-color: $black transparent transparent;
                            right: 0;
                        }
                        @include breakpoint(large up) {
                            padding-right: rem-calc(15);
                        }
                    }
                }
    
                .submenu {
                    background: $white;
                    li {
                        display: block;
                        a {
                            font-size: rem-calc(14);
                            line-height: 1.4;
                            @include breakpoint(large up) {
                                text-align: left;
                                padding: rem-calc(10 15);
                            }
                        }
                        
                        &:hover {
                            background: $yellow;
                        }
                    }
                }
            }
        }
    }

    /*******************************************************************************
	MEGAMENU
    ********************************************************************************/
    #megamenu {
        .megamenu-line-right {

            @include breakpoint(large up) {
                display: none;
            }
        }
    }

    .megamenu-trigger {
    
        @include breakpoint(large up) {
            display: none;
        }
    }

    /*******************************************************************************
	SEARCH
    ********************************************************************************/
    .header-search-trigger {
        @include breakpoint(large up) {
            margin-right: 0;
        }
    }

    /*******************************************************************************
	SEARCH
    ********************************************************************************/
    .home-hero {
        .home-hero-wrapper {
            .home-hero-image {
                &:before {
                    background: rgba(10, 10, 10, 0.1);
                }
            }
        }
    }
}