.home-numbers {
    padding-bottom: rem-calc(80);
    
    .number-row {
        @include breakpoint(large) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            position: relative;
        }
    
        &:before {
            @include breakpoint(large) {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                top: rem-calc(35)*0.75;
                left: 0;
                border-bottom: rem-calc(1) solid $gray;
                z-index: 1;
            }
            
            @include breakpoint(xxlarge) {
                top: rem-calc(35);
            }
        }
        
        .number-column {
            flex: 1;
            padding: rem-calc(0 15);
            text-align: center;
            width: 100%;
            position: relative;

            &:not(:last-of-type) {
                padding-bottom: 45px;

                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 35px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    background-color: $black;

                    @include breakpoint(large) {
                        display: none;
                    }
                }
            }
            
            .number-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                @include breakpoint(xxlarge) {
                    display: inline-block;
                }
                
                h3 {
                    font-family: $font-alt;
                    color: $yellow;
                    font-size: rem-calc(70) * 0.75;
                    font-weight: 900;
                    margin: rem-calc(0 0 10 0);
                    line-height: 1;
                    position: relative;
                    display: inline-block;
                    padding: rem-calc(0 15);
                    background: $white;
                    z-index: 2;
                    
                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(70);
                    }
                }
        
                p {
                    font-size: rem-calc(18) * 0.75;
                    margin: 0;
                    
                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(18);
                    }
                }
            }
        }
    }
}