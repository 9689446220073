@import url('https://fonts.googleapis.com/css?family=Merriweather:300,400,700,900|Open+Sans:300,400,700,800&subset=latin-ext');
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

$font-base : 'Open Sans', sans-serif;
$font-alt : 'Merriweather', serif;

$yellow : #f2d200;
$gray : #888888;

body {
  font-family: $font-base;
  font-size: rem-calc(18);
  font-weight: 300;
  overflow-x: hidden !important;
}
.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: left;
}

aside {
  min-height: 1px;
}
/* Buttons */
.button, .button-lang {
  font-family: $font-alt;
  font-size: rem-calc(18);
  line-height: rem-calc(20);
  padding: rem-calc(10 50);
  font-weight: 300;  

  @include breakpoint(xlarge down) {
    font-size: rem-calc(18) * 0.75;
    line-height: rem-calc(20) * 0.75;
    padding: rem-calc(10  * 0.75 50 * 0.75);
  }

  &.primary {
    background: $white;
    color: $black;
    border: rem-calc(1) solid $black;

    &:hover, &:focus, &:active {
      background: $yellow;
      color: $black;
      border: rem-calc(1) solid $yellow;
    }
  }
}

.row {
  @include breakpoint(xlarge down) {
    max-width: rem-calc(1000);
  }
}

/* Typography */
p {
  font-size: rem-calc(16);

  @include breakpoint(xlarge down) {
    font-size: rem-calc(15);
  }
}
h1,h2,h3,h4,h5,h6 {
  font-family: $font-alt;
  font-weight: 700;
}
a {
  h1,h2,h3,h4,h5 {
    color: $black;
  }
}
.header-yellow {
  text-align: center;
  font-weight: 700;
  font-size: rem-calc(34);
  color: $yellow;
  margin-bottom: rem-calc(30);
}
/* Forms */
.form-control {
  position: relative;
  overflow: hidden;

  label {
    position: absolute;
    z-index: 10;
    font-size: rem-calc(18);
    font-family: $font-base;
    font-weight: 300;
    padding: rem-calc(8);
    line-height: rem-calc(20);
    transition: all 0.3s ease;
    pointer-events: none;
  }

  &.form-control--focus, &.form-control--filled {

    label {
      transform: translateX(-100%);
    }
  }

  input, textarea {
    border: 1px solid $gray;
    box-shadow: none !important;
    outline: 0;
    font-size: rem-calc(18);
    font-family: $font-base;
    font-weight: 300;
    line-height: rem-calc(20);
  }

  textarea {
    height: rem-calc(240);
    resize: none;
  }
}

.FormButton {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  -webkit-appearance: none;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  text-align: center;
  cursor: pointer;
  font-family: "Merriweather", serif;
  font-size: 1.125rem;
  line-height: 1.25rem;
  padding: 0.625rem 3.125rem;
  font-weight: 300;
  background: $white;
  color: #0a0a0a;
  border: 0.0625rem solid #0a0a0a;

  &:hover, &:focus {
    background: $black;
    color: $white;
  }
}