.home-page-info {
	background: $yellow url(../img/dots-info.jpg) repeat-x top center;
	padding: rem-calc(90 0 175);
	position: relative;

    &:before,
    &:after {
		content: "";
		display: block;
		position: absolute;
		left: 50%;
		margin-left: -1px;
		border-left: rem-calc(1) solid $white;
	}

	&:before {
		height: rem-calc(90);
		top: 0;
	}
	&:after {
		height: rem-calc(85);
		bottom: 0;
	}

	h2 {
		font-size: rem-calc(60);
		font-weight: 900;
		color: $white;
		text-align: center;
		margin-bottom: rem-calc(50);

		@include breakpoint(xlarge down) {
			font-size: rem-calc(60) * 0.75;
		}
	}
}