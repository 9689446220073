.home-hero {
	height: 100vh;
	padding-bottom: rem-calc(30);
	position: relative;

	.home-hero-wrapper {
		width: 100%;
		height: 100%;
		position: relative;

		.home-hero-video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center center;
			background-size: cover;
			z-index: 2;
		
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($black, .65);
			}
		}

		.home-hero-image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center center;
			background-size: cover;
		
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($black, .65);
			}
		}

		.home-hero-content {
			display: flex;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 10;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			
			h1 {
				color: $white;
				font-family: $font-alt;
				font-size: rem-calc(60) * 0.75;
				font-weight: 700;
				position: relative;
				margin-bottom: rem-calc(15);
				padding: 0 0.9375rem;

				@include breakpoint(medium) {
					padding: 0;
				}
				@include breakpoint(xxlarge) {
					font-size: rem-calc(60);
				}
		
				&:after {
					content: '';
					display: block;
					width: rem-calc(270) * 0.75;
					height: rem-calc(1);
					background: $white;
					opacity: 0.5;
					margin: rem-calc(15) auto 0 auto;
					
					@include breakpoint(xxlarge) {
						width: rem-calc(270);
					}
				}
			}
		
			p {
				text-transform: uppercase;
				font-size: rem-calc(20) * 0.75;
				font-weight: 300;
				color: $white;
				max-width: rem-calc(600) * 0.75;
				text-align: center;
				margin: 0 rem-calc(20);
				
				@include breakpoint(xxlarge) {
					font-size: rem-calc(20);
					max-width: rem-calc(600);
				}
			}
		}
	}

	.home-hero-frame {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding-bottom: rem-calc(30);
		z-index: 20;
	
		.row,
		.column {
			height: 100%;
			position: relative;
			pointer-events: none;
		}
	
		.frame {
			height: 100%;
			border-left: rem-calc(1) solid rgba($white, 0.5);
			border-right: rem-calc(1) solid rgba($white, 0.5);
			pointer-events: none;
		}
	
		.home-hero-scroll {
			display: inline-block;
			position: absolute;
			bottom: rem-calc(30);
			left: 50%;
			font-size: rem-calc(14);
			font-family: $font-alt;
			color: $yellow;
			padding: rem-calc(15);
			z-index: 10;
	
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				bottom: 0;
				height: rem-calc(180) * 0.75;
				border-left: rem-calc(1) solid rgba($white, 0.5);
				
				@include breakpoint(xxlarge) {
					height: rem-calc(180);
				}
			}
		}
	}

	.hero-slider {
		.swiper-container {
			position: static;
			width: 100%;
			height: 100%;
		}

		.swiper-wrapper {
			position: static;
		}
		.swiper-slide {
			// position: static;
		}
	}
}

