/*******************************************************************************
	GLOBAL
********************************************************************************/
.page-hero {
	padding: rem-calc(108 0 0 0);
	background: url(../img/page-hero.jpg) no-repeat center center;
	background-size: cover;
	margin-bottom: rem-calc(30);
	
	@include breakpoint(xxlarge) {
		padding: rem-calc(144 0 0 0);
	}

	h1 {
		font-size: rem-calc(40);
		font-weight: 700;
		margin: 0;
		color: $white;
		padding: rem-calc(45 0);
		border-left: rem-calc(1) solid rgba($white, 0.5);
		border-right: rem-calc(1) solid rgba($white, 0.5);
		position: relative;

		&:after {
			content: '';
			display: block;
			height: rem-calc(34);
			border-right: rem-calc(1) solid rgba($white, 0.5);
			position: absolute;
			left: 50%;
			bottom: 0;
		}
	}
}

.dots-decoration-left {
	position: relative;

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 100%;
		top: rem-calc(200);
		width: rem-calc(1920);
		margin-left: rem-calc(30);
		height: rem-calc(430);
		background-image: url(../img/dots-form.jpg);
	}
}

.pagination {
	text-align: center;

	.SelectedPage, a {
		display: inline-block;
	}

	.SelectedPage {
		font-weight: bold;
	}

	a {
		background: transparent;

		&:hover {
			background: transparent;
			color: $yellow;
		}
	}
}

.row[class*=main] {
	p {
		max-width: rem-calc(850);
		margin: 0 auto rem-calc(30);

		strong {
			font-size: rem-calc(20);
		}
	}

	h1, h2, h3, h4 {
		margin-bottom: rem-calc(30);
		max-width: rem-calc(850);
		margin: 0 auto rem-calc(30);
	}
}

.subscription-text {
	a {
		color: $black;
		transition: color 0.3s ease;

		&:hover {
			color: #d0b500;
		}
	}
}

/*******************************************************************************
	SIDEBAR
********************************************************************************/
.sidebar-filter, .sidebar-date, .sidebar-menu {
	padding-left: rem-calc(15);
	padding-bottom: rem-calc(30);
	position: relative;
	margin-top: rem-calc(-30);
	padding-top: rem-calc(30);

	&:before {
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		border-left: rem-calc(1) solid #959595;
		left: 0;
		top: 0;
	}

	h3 {
		color: $yellow;
		font-size: rem-calc(25);
		font-weight: 700;
	}

	.CategoryListList {

		li {
			list-style: none;

			a {
				color: $black;
				font-size: rem-calc(14);
			}
		}
	}
}

.sidebar-menu {

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			margin-bottom: rem-calc(10);
			line-height: 1.1;

			a {
				color: $black;
				font-size: rem-calc(14);
			}

			a.CMSListMenuLinkHighlighted {
				font-weight: 700;
			}

			ul {
				padding-left: 10px;
				padding-top: 10px;
			}
		}
	}
}
/*******************************************************************************
	NEWS
********************************************************************************/
.event-list {
	padding-top: 50px;
}

.news-list,
.event-list {

	.news-list-item {
		margin-bottom: rem-calc(50);

		h2 {
			font-size: rem-calc(32);
			font-weight: 700;
			margin: 0;
		}

		.news-date {
			display: inline-block;
			font-size: rem-calc(21);
			padding-left: rem-calc(10);
			border-left: rem-calc(1) solid $black;
			font-weight: 300;
			margin: rem-calc(15 0);
		}

		.news-excerpt {
			margin-bottom: rem-calc(30);

			p:last-child {
				margin-bottom: 0;
			}
		}

		.news-list-footer {
			text-align: right;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: 100%;
				border-bottom: rem-calc(1) solid $black;
				position: absolute;
				top: 50%;
			}

			.button {
				margin: 0;
				position: relative;
				z-index: 10;
			}
		}
	}
}

.news-details {

	.news-thumbnail {

		img {
			max-width: 100%;
			height: auto !important;
			margin-bottom: rem-calc(30);
		}
	}

	.news-summary {
		margin-bottom: rem-calc(30);
		font-weight: 700;
		font-size: rem-calc(25);

		p {
			font-size: rem-calc(25);
		}
	}

	.news-text {
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 100%;
			margin-left: rem-calc(-50);
			top: 50%;
			margin-top: rem-calc(-240);
			width: rem-calc(1920);
			height: rem-calc(480);
			background-image: url(../img/dots-form.jpg);
			z-index: -1;
		}

		img {
			max-width: 100%;
			height: auto !important;
			margin-bottom: rem-calc(30);
		}
	}
}
/*******************************************************************************
	CONTACT
********************************************************************************/
.column-contact {

	h3 {
		font-weight: 700;
		font-size: rem-calc(25);
		color: $yellow;
	}

	a {
		color: $black;

		&:hover {
			color: $yellow;
		}
	}
}

.contact-page-form {
	position: relative;
	padding-bottom: rem-calc(80);

	&:before {
		@include breakpoint(large) {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			height: rem-calc(900);
			border-left: rem-calc(1) solid $gray;
		}
	}
	
	&:after {
		@include breakpoint(large) {
			content: '';
			display: block;
			position: absolute;
			left: 100%;
			top: 0;
			width: rem-calc(1920);
			margin-left: rem-calc(30);
			height: rem-calc(430);
			background-image: url(../img/dots-form.jpg);
		}
	}

	.FormButton {
		position: absolute;
		bottom: rem-calc(0);
		margin: 0;
		right: 0;
	}
}

.contact-map {
	margin-top: 50px;

	h2 {
		font-size: rem-calc(30);
		font-weight: 700;
		color: $yellow;
		border-left: rem-calc(1) solid $gray;
		margin: 0;
		padding: rem-calc(0 0 15 15);
	}

	+ #footer .footer-groups {
		margin-top: 0;
	}
}

/*******************************************************************************
KALENDARZ
*******************************************************************************/
.calendar-no-event {
	display: none;
}

.event-calendar {
	box-shadow: 0px 2px 15px 1px rgba(0,0,0,0.05);

	.has-tip {
		cursor: pointer;
	}

	> table {
		border: 0 !important;

		td, tr, th {
			border: 0;
			background: none;
		}

		> tbody {
			border: 0;
			background: none;

			tr:first-child {

				> td {
					background: $yellow !important;

					table {
						margin: 0;
					}

					tbody {
						border: 0;
						background: none;

						td {
							font-family: $font-alt;
							font-weight: 700;
						}
					}
				}
			}

			tr:nth-child(n+2) {
				background: none;

				td {
					vertical-align: middle;

					&.has-event {
						background: rgba($yellow, 0.4);
						font-weight: 700;
					}
				}
			}
		}
	}
}
/*******************************************************************************
SUBPAGES GRID
*******************************************************************************/
.main-subpages {
	margin-top: rem-calc(-30);

	+ #footer .footer-groups {
		margin-top: 0;
	}

	.home-page-info {
		//background: $white url(../img/dots-yellow.png) repeat-x top center;

		&:before, &:after {
			border-color: $black;
		}
	}
}

/*******************************************************************************
GALLERY
*******************************************************************************/
.gallery-list {
	.gallery-list-item {
		margin-bottom: rem-calc(60);
		@include clearfix;

		.gallery-list-padding {
			@include breakpoint(small down) {
				display: flex;
				flex-direction: column;
			}
		}

		.gallery-list-thumbnail {
			position: relative;
			width: 100%;
			float: left;
			padding-bottom: 80%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			
			@include breakpoint(medium) {
				width: 50%;
				padding-bottom: 40%;
			}
		}

		.gallery-list-details {
			position: relative;
			width: 100%;
			float: left;
			padding: rem-calc(15 0 45 0);
			position: relative;
			
			@include breakpoint(medium) {
				width: 50%;
				padding: rem-calc(0 0 100 30);
			}

			h3 {
				font-size: rem-calc(26);
				font-weight: 700;
				margin-bottom: rem-calc(15);
				
				@include breakpoint(large) {
					font-size: rem-calc(32);
				}
			}
		}

		.gallery-list-more {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 100%;
			text-align: right;

			&:before {
				content: '';
				display: block;
				width: 100%;
				border-bottom: rem-calc(1) solid $black;
				position: absolute;
				top: 50%;
				z-index: -1;
			}

			.button {
				margin: 0;
			}
		}

		.gallery-list-meta {
			padding: rem-calc(5 0 5 15);
			margin-bottom: rem-calc(15);
			border-left: rem-calc(1) solid $black;

			span {
				display: block;
				margin-bottom: rem-calc(5);
			}

			a {
				color: $black;


				&:hover {
					color: $yellow;
				}
			}
		}

		&:nth-child(even) {
			.gallery-list-thumbnail {				
				@include breakpoint(medium) {
					left: 50%;
				}
			}

			.gallery-list-details {
				padding: rem-calc(15 0 45 0);
				
				@include breakpoint(medium) {
					padding: rem-calc(0 30 100 0);
					left: -50%;
				}
			}

			.gallery-list-more {
				text-align: left;
			}
		}
	}
}


#gallery-slider, #gallery-nav {
	position: relative;

	.swiper-image {
		padding-bottom: 65%;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.swiper-button-next {
		transform: translateX(100%);
		opacity: 0;
		transition: all 0.3s ease;
	}

	.swiper-button-prev {
		transform: translateX(-100%);
		opacity: 0;
		transition: all 0.3s ease;
	}

	&:hover {

		.swiper-button-next,
		.swiper-button-prev {
			transform: translateX(0);
			opacity: 1;
		}
	}
}

#gallery-grid {
	@include clearfix;

	.grid-item {
		float: left;
		width: 100% !important;
		margin-bottom: rem-calc(30);
		padding-left: rem-calc(15);
		padding-right: rem-calc(15);
		position: relative;
		
		@include breakpoint(medium) {
			width: 50% !important;
		}
		
		@include breakpoint(large) {
			height: rem-calc(180);
			width: 33.33333%;
		}
		
		.swiper-image {
			min-width: 100%;
			max-width: 100%;
			padding-bottom: 60%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			@include breakpoint(medium) {
				padding-bottom: 75%;
			}
			
			@include breakpoint(large) {
				width: 100%;
				height: 100%;
				padding-bottom: 0;
			}
		}

		&:nth-child(10n + 1) {
			@include breakpoint(large) {
				width: 66.666666%;
				height: rem-calc(390);
			}
		}

		&:nth-child(10n + 5) {
			@include breakpoint(large) {
				width: 66.666666%;
			}
		}

		&:nth-child(10n + 6) {
			@include breakpoint(large) {
				width: 66.666666%;
			}
		}
	}
}

#gallery-slider {
	height: 100%;
	padding-bottom: rem-calc(200);

	.swiper-container {
		height: 100%;
	}

	.swiper-slide {

		.swiper-image {
			height: 100%;
			padding: 0;
		}

		a {
			display: block;
			position: relative;
			overflow: hidden;

			&:after {
				content: '';
				display: block;
				width: 50px;
				height: 52px;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -25px;
				margin-left: -26px;
				opacity: 0;
				transform: scale(1.2);
				background: url(../img/lupa-white.png) no-repeat center center;
				transition: all 0.3s ease;
			}

			&:hover {
				&:before {
					opacity: 1;
				}
				&:after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}
}

#gallery-nav {
	position: absolute;
	height: rem-calc(200);
	width: 100%;
	bottom: 0;
	left: 0;

	&:before {
		content: '';
		display: block;
		border-left: rem-calc(1) solid $black;
		height: rem-calc(30);
		position: absolute;
		top: rem-calc(-30);
		left: 50%;
	}

	.swiper-image {
		cursor: pointer;
		background-size: cover;
		height: rem-calc(200);
	}
}

#gallery-modal {
	//display: block;
}

/*******************************************************************************
	LANDING
********************************************************************************/
.landing-content {
	position: relative;
	padding-bottom: rem-calc(50);

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 125rem;
		height: rem-calc(500);
		right: 90%;
		top: -5rem;
		background-image: url(../img/dots-intro.jpg);
		z-index: -1;
	}

	&:after {
		display: none !important;

		content: '';
		display: block;
		position: absolute;
		width: 125rem;
		height: rem-calc(500);
		left: 90%;
		bottom: -5rem;
		background-image: url(../img/dots-intro.jpg);
		z-index: -1;
	}

	ul {
		list-style-type: none;

		li {
			position: relative;
			padding-left: 25px;

			&:before {
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 5px;
				display: block;
				background-color: $yellow;
				position: absolute;
				top: 11px;
				left: 0;
			}
		}
	}
}
/*******************************************************************************
	PLANY LEKCJI
********************************************************************************/
.parent-70 {
	overflow: auto;
	
	img {
		max-width: none;
		
		@include breakpoint(large) {
			max-width: 100%;
			height: auto !important;
		}
	}
}