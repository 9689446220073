/*******************************************************************************
	HEADER
********************************************************************************/
#header {
	width: 100%;
	pointer-events: none;

	.header-fixed {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	}

	.top-bar {
	padding: 0;
	background: transparent;
	position: relative;
}

#menuElem {
	list-style: none;
	margin: 0;
	padding: 0;
	background: none;
	pointer-events: all;
	margin-left: rem-calc(107);
	display: flex;
	flex-direction: row;

	@include breakpoint(xxlarge) {
		margin-left: rem-calc(143);
	}

	li {
		display: inline-block;

		a {
		color: $black;
		font-family: $font-alt;
		font-size: rem-calc(18);
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.02rem;
		padding: rem-calc(15 20);

		@include breakpoint(xxlarge) {
			font-size: rem-calc(20);
			padding: rem-calc(15 30);
		}

		&:hover {
			color: $white;
		}
		}
	}
	}
}

/*******************************************************************************
	SEARCH
********************************************************************************/
.header-search-trigger {
	height: rem-calc(144)*0.75;
	width: rem-calc(144)*0.75;	
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	z-index: 100;
	cursor: pointer;
	pointer-events: all;
	
	@include breakpoint(medium) {
		margin: 0 rem-calc(144)*0.75 0 auto;
	}
	
	@include breakpoint(xxlarge) {
		margin: 0 rem-calc(144) 0 auto;
		height: rem-calc(144);
		width: rem-calc(144);
	}

	.header-search-icon {
	display: block;
	width: rem-calc(50);
	height: rem-calc(52);
	background: url(../img/lupa.png) no-repeat center center;
	background-size: contain;
	transform: scale(0.75);
	transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@include breakpoint(xlarge down) {
		width: rem-calc(50) * 0.75;
		height: rem-calc(52) * 0.75;
	}
	}

	&.header-search--active {

	.header-search-icon {
		transform: scale(1);
	}
	}
}

.header-search {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	padding: rem-calc(0 145 0 143);
	opacity: 0;
	transition: all 0.5s ease;
	pointer-events: none;
	z-index: 99;

	@include breakpoint(xlarge down) {
	padding-left: rem-calc(143) * 0.75;
	padding-right: rem-calc(145) * 0.75;
	right: rem-calc(145) * 0.75;
	}

	.header-search-wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	pointer-events: none;
	}

	label {
	position: absolute;
	z-index: 10;
	font-family: $font-alt;
	font-size: rem-calc(48);
	line-height: rem-calc(60);
	font-weight: 300;
	color: rgba($black, 0.5);
	padding: rem-calc(42);
	pointer-events: none;
	transform: translateX(-100%);
	transition: all 0.3s ease;

	@include breakpoint(xlarge down) {
		font-size: rem-calc(48) * 0.75;
		line-height: rem-calc(60) * 0.75;
		padding: rem-calc(42) * 0.75;
	}
	}

	input[type="text"] {
	width: 100%;
	max-width: 100%;
	border: 0;
	height: rem-calc(144);
	font-family: $font-alt;
	font-size: rem-calc(48);
	line-height: rem-calc(60);
	padding: rem-calc(42 144 42 42);
	font-weight: 300;
	color: $black;
	background: #d5b904;
	outline: none;
	box-shadow: none;
	margin: 0;

	@include breakpoint(xlarge down) {
		height: rem-calc(144) * 0.75;
		font-size: rem-calc(48) * 0.75;
		line-height: rem-calc(60) * 0.75;
		padding-top: rem-calc(42) * 0.75;
		padding-right: rem-calc(144) * 0.75;
		padding-bottom: rem-calc(42) * 0.75;
		padding-left: rem-calc(42) * 0.75;
	}
	}

	input[type="submit"] {
	display: none;
	}

	&.header-search--focus, &.header-search--filled {

	label {
		transform: translateX(-100%) !important;
	}
	}
}
.search-expanded {

	.header-search {
	opacity: 1;

	.header-search-wrapper {
		pointer-events: all;
	}

	label {
		transform: translateX(0%);
	}
	}
}
/*******************************************************************************
	MEGAMENU
********************************************************************************/
#megamenu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999;

	.megamenu-overlay {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background: $yellow;
	}

	.megamenu-line-left {
	position: absolute;
	top: 0;
	left: rem-calc(142);
	height: 100%;
	border-left: rem-calc(1) solid #c2a800;

	@include breakpoint(xlarge down) {
		left: rem-calc(142) * 0.75;
	}
	}

	.megamenu-line-right {
	position: absolute;
	top: 0;
	right: rem-calc(144);
	height: 100%;
	border-left: rem-calc(1) solid #c2a800;

	@include breakpoint(xlarge down) {
		right: rem-calc(144) * 0.75;
	}
	}

	.megamenu-logo {
	position: absolute;
	left: rem-calc(33);
	top: rem-calc(28);

	@include breakpoint(xlarge down) {
		left: rem-calc(33) * 0.75;
		top: rem-calc(28) * 0.75;
	}

	a {
		display: block;
		width: rem-calc(80);
		height: rem-calc(88);
		overflow: hidden;

		@include breakpoint(xlarge down) {
		width: rem-calc(80) * 0.75;
		height: rem-calc(88) * 0.75;
		}

		img {
		height: rem-calc(88);
		width: auto;
		max-width: none;

		@include breakpoint(xlarge down) {
			height: rem-calc(88) * 0.75;
		}

		}
	}
	}

	.megamenu-menu {
	position: absolute;
	width: 100%;
	height: 100%;
	//padding-top: rem-calc(144);
	pointer-events: none;

	.megamenu-expanded & {
		pointer-events: all;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.megamenu-nav {
		width: rem-calc(380);
		height: 100%;
		display: flex;
		align-items: center;

		@include breakpoint(xlarge down) {
		width: rem-calc(295);
		}

		> ul {
		width: 100%;

		> li {
			position: relative;
			width: 100%;

			> a {
			display: block;
			font-size: rem-calc(30);
			font-family: $font-alt;
			font-weight: 900;
			color: $black;
			text-align: center;
			padding: rem-calc(30 15);
			transform: translateY(-20px);
			opacity: 0;
			position: relative;

			@include breakpoint(xlarge down) {
				font-size: rem-calc(30) * 0.75;
				padding: rem-calc(30) * 0.75 rem-calc(15) * 0.75;
			}

			&:after {
				content: '';
				display: block;
				width: rem-calc(43);
				height: rem-calc(8);
				background: $white;
				position: absolute;
				right: rem-calc(-22);
				top: 50%;
				margin-top: rem-calc(-4);
				opacity: 0;
				transform: translate3d(-22px,0,0);
				transition: all 0.3s ease;

				@include breakpoint(xlarge down) {
				width: rem-calc(43) * 0.75;
				height: rem-calc(8) * 0.75;
				right: rem-calc(-22) * 0.75;
				margin-top: rem-calc(-4) * 0.75;
				transform: translate3d(-16px,0,0);
				}
			}
			}

			> ul {
			position: absolute;
			left: 100%;
			top: 50%;
			transform: translate3d(0,-50%,0);
			padding-left: rem-calc(100);
			width: auto;
			pointer-events: none;

			> li {

				> a {
				display: inline-block;
				padding: rem-calc(15 0);
				font-size: rem-calc(30);
				font-weight: 300;
				color: $black;
				white-space: nowrap;
				transform: translateX(-20px);
				opacity: 0;
				transition: transform 0.3s ease, opacity 0.2s ease;

				@include breakpoint(xlarge down) {
					padding: rem-calc(15) * 0.75 0;
					font-size: rem-calc(30) * 0.75;
					transform: translateX(-20px * 0.75);
				}

				&:hover {
					color: $white;
				}
				}
			}
			}

			&:hover {

			> a {

				&:after {
				opacity: 1;
				transform: translate3d(0,0,0);
				}
			}

			> ul {
				pointer-events: all;

				> li {

				@for $i from 1 to 10 {
					&:nth-child(#{$i}) > a {
					transition: transform 0.3s ease $i * 0.1s, opacity 0.2s ease $i * 0.1s, color 0.1s ease;
					}
				}

				> a {
					transform: translateX(0);
					opacity: 1;
				}
				}
			}
			}
		}
		}
	}
	}
}

.megamenu-trigger {
	height: rem-calc(144);
	width: rem-calc(144);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 100;

	@include breakpoint(xlarge down) {
	height: rem-calc(144) * 0.75;
	width: rem-calc(144) * 0.75;
	}

	.megamenu-icon {
	width: rem-calc(43);
	height: rem-calc(34);
	position: relative;

	@include breakpoint(xlarge down) {
		width: rem-calc(43) * 0.75;
		height: rem-calc(34) * 0.75;
	}

	span {
		display: block;
		width: 100%;
		height: rem-calc(8);
		background: $black;
		position: absolute;
		left: 50%;
		margin-left: rem-calc(-21);
		transition: all 0.2s ease;

		@include breakpoint(xlarge down) {
		height: rem-calc(8) * 0.75;
		margin-left: rem-calc(-21) * 0.75;
		}

		&:nth-child(1) {
		top: 0;
		}

		&:nth-child(2),
		&:nth-child(3) {
		bottom: rem-calc(13);

		@include breakpoint(xlarge down) {
			bottom: rem-calc(13) * 0.75;
		}
		}

		&:nth-child(4) {
		bottom: 0;
		}
	}
	}
}

.megamenu-expanded {

	.megamenu-trigger {

	.megamenu-icon {

		span {


		&:nth-child(1) {
			width: 0;
			margin-left: 0;
		}

		&:nth-child(2) {
			transform: rotate(45deg) translate3d(0,0,0);
			transition-delay: 0.2s;
		}

		&:nth-child(3) {
			transform: rotate(-45deg) translate3d(0,0,0);
			transition-delay: 0.2s;
		}

		&:nth-child(4) {
			width: 0;
			margin-left: 0;
		}
		}
	}
	}
}

@include breakpoint(medium down) {
	#header #menuElem{
		display: none;
	}
	#megamenu{
		.megamenu-logo{
		z-index: 999;
		}
	.megamenu-menu{
		pointer-events: auto;
		.megamenu-nav{
		display: none;
		width: 100%;
		height: 70vh;
		margin-top: 100px;
		background: #f2d200;
		overflow-y: scroll;
		&> ul > li > a,
		&> ul > li > ul > li > a{
			opacity: 1;
			transform: none;
		}
		&> ul > li > a{
			&::after{
			content: none;
			}
		}
		&> ul > li > ul > li > a{
			padding: 0;
		}
		&> ul > li > ul{
			position: static;
			transform: none; 
			text-align: center;
			padding-left: 0;
		}
		}
	} 
	}
	.megamenu-expanded{
	.megamenu-nav{
		display: block !important;
	}
	}
	.search-expanded .header-search{
	label,
	input[type="text"]{
	font-size: 20px;
	}
	} 
}
@include breakpoint(small down) {
	.search-expanded .header-search{
	transform: translateY(100%);
	padding: 0; 
	}
}